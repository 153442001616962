// #openBox{
//   z-index: auto !important;
// }
// * {
//   font-family: "TomatoGrotesk" !important;
// }

.font-weight-600 {
  font-weight: 900;
}

:root {
  --font-mal: serif;
  --font-en: "Poppins";
}

#root #base.mal {
  font-family: var(--font-mal) !important;
}
#base.en {
  font-family: var(--font-en) !important;
}

.btn-khaf {
  background-color: #009846 !important;
  border: none;
}

.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.overflow-div {
  overflow-y: scroll;
  max-height: 500px;
}

.overflow-div::-webkit-scrollbar {
  display: none;
}

.fc .fc-toolbar-title {
  font-size: 1.3em;
}

.fc-daygrid-event {
  border: none;
}

.selection-cell {
  display: none;
}

.selection-cell-header {
  display: none;
}

#select-cell .selection-cell {
  display: block !important;
}

#select-cell .selection-cell-header {
  display: block !important;
}

// .overflowScroll {
//   // overflow-x: scroll;

// }
.max-h-5::-webkit-scrollbar,
.overflowScroll::-webkit-scrollbar {
  display: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #444444 !important;
}

.btn.custom_border_rad {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}

.custome_select_rad .css-yk16xz-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.createClintBtn {
  padding-right: 0px;
}

.createClintBtnCont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.borderBottomWhite {
  border-bottom: 1px solid #fff;
}

.myWidth30 {
  width: 30% !important;
}

.myWidth20 {
  width: 20% !important;
}

.width-fit {
  width: fit-content !important;
  margin-left: auto;
}
.cardleft {
  min-width: 250px !important;
}
.minimus-item {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;

  &.active {
    opacity: 0;
    visibility: hidden;
    display: none !important;
    animation: hideElement 0.3s ease-in-out 0.3s forwards;
  }

  @keyframes hideElement {
    to {
      display: none !important;
    }
  }
}

@media print {
  .bg-info-new {
    color: #000 !important;
    background-color: #f8f9fa !important;
    -webkit-print-color-adjust: exact;
  }

  .bg-light-new {
    background-color: #f8f9fa !important;
    -webkit-print-color-adjust: exact;
  }

  .print-pb-3 {
    padding-bottom: 2rem;
  }

  .print-pt-3 {
    padding-top: 2rem;
  }

  .print-boxshadow {
    box-shadow: none !important;
  }
  .hide-print-item {
    display: none !important;
  }
  .no-print {
    display: none !important;
  }
}

@media screen and (max-width: 986px) {
  .createClintBtn,
  .createClintBtnCont {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

@media screen and (max-width: 600px) {
  .d-none-smo {
    display: none;
  }
}

.premium {
  cursor: pointer;

  :hover {
    pointer-events: none;
  }
}

.myFLex {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

// .number-label{
//   background: rgb(66, 148, 254);
//   color: rgb(255, 255, 255);
//   padding: 7px;
//   border-radius: 12px;
// }

.number-label {
  border-radius: 3em;
  -moz-border-radius: 3em;
  -webkit-border-radius: 3em;

  height: 2em;
  width: 2em;
  display: inline-block;
  font-family: "Roboto", Helvetica, Sans-serif;
  font-size: 14px;
  line-height: 1.8em;
  text-align: center;
  border: 1px solid #4294fd;
}

.number-label:hover {
  background: #ff5a00;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.line-between {
  height: 2px;
  position: absolute;
  top: 18%;
  left: 70%;
  width: 50px;

  @media screen and (max-width: 580px) {
    left: 77%;
    width: 60px;
  }
}

.modal_section {
  top: 450px;
  width: 100%;

  @media screen and (max-width: 360px) {
    position: unset !important;
    height: 100%;
  }
}

.sm\:d-none {
  @media screen and (max-width: 360px) {
    display: none !important;
  }
}

.home_wrapper {
  height: 100% !important;
  padding-bottom: 0 !important;
  // height: 85vh;
  // @media screen and (min-width: 992px) {
  //   height: 85vh;
  // }
}

@media screen and (min-width: 1440px) {
  .content-certificate {
    margin-left: 120px;
  }
  .address-p {
    font-size: medium;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1440px) {
  .committe-p {
    width: 110px;
  }
}

@media screen and (max-width: 1440px) {
  .img-demo {
    height: 254px !important;
  }
  .committe-p-main {
    width: 120px;
  }
}

@media screen and (min-width: 1500px) {
  .content-certificate {
    margin-left: 250px;
  }

  .content-text {
    padding-left: 100px;
  }
}

.miniCardSection {
  // margin-top: 20rem;
  height: auto !important;
  // @media screen and (max-width: 1024px) {

  // }
  // @media screen and (max-width: 768px) {
  //   margin-top: 12rem;
  // }
  // @media screen and (max-width: 425px) {
  //   margin-top: 2rem;
  // }
  // @media screen and (max-width: 320px) {
  //   margin-top: -2rem !important;
  // }
}

.home_cont {
  padding-top: 11rem;
  // height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.card-poup {
  max-width: 300px;
  margin-top: -5rem;
  height: fit-content;
}

.myp-5 {
  padding: 3rem;
}

.otp_input {
  height: 100%;
  width: 100% !important;
  border: #adb5bd solid;
  border-radius: 5px;

  @media screen and (max-width: 300px) {
    border: none;
  }
}

.otp_container div {
  width: 40px;
  height: 40px;
}

.otp_focus {
  border: rgb(0, 0, 0) solid !important;

  @media screen and (max-width: 320px) {
    border: none !important;
  }
}

@media screen and (min-width: 2560px) {
  .content-certificate {
    margin-left: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .varisnkya {
    padding-left: 5px !important;
  }
  .img-demo {
    height: 252px !important;
  }

  .address-p {
    font-size: 12px;
  }

  .md\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .card-poup {
    max-width: 300px;
    margin-bottom: 100px;
    margin-top: -5rem;
    height: fit-content;
  }

  .content-certificate {
    margin-left: 100px;
  }

  .p-card {
    margin: 20px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 580px) {
  .secondDiv {
    width: 30%;
  }

  .home_cont {
    margin-top: 0%;
  }

  .myp-5 {
    padding: 1rem;
  }
}
@media screen and (max-width: 1500px) {
  .mahall-type {
    // left:480px !important;
  }
}
@media screen and (max-width: 470px) {
  .startjustify {
    justify-content: start !important;
  }
  .cardleft {
    min-width: 130px !important;
  }
  .idbadge {
    justify-content: start !important;
    padding-bottom: 1rem;
  }
  .cardfirstsection {
    min-width: 150px !important;
  }
  .buttonright {
    min-width: auto !important;
  }
  .singleviewcard {
    height: 160px !important;
  }
  .familybuttons {
    padding-bottom: 2rem !important;
  }
  .spandemmy {
    display: none !important;
  }
  .housename {
    align-self: center !important;
  }

  .familycard {
    flex-direction: column;
    height: 70px !important;
  }
  .familyyear {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm\:mt-4 {
    margin-top: 2rem;
  }

  .home_cont {
    margin-top: 0%;
    padding-bottom: 100px;
  }

  .section {
    padding-bottom: 0px;
    padding-top: 50px;
  }

  .card-poup {
    max-width: 300px;
    margin-bottom: 100px;
    margin-top: -5rem;
    height: fit-content;
  }

  .line-between {
    height: 2px;
    position: absolute;
    top: 18%;
    left: 75%;
    width: 50px;
  }

  .sm\:flex-col {
    flex-direction: column-reverse !important;
  }

  .firstDiv,
  .secondDiv {
    width: 100%;
  }
  .main-div {
    text-align: center;
  }
  .mahall-name,
  .mahall-date,
  .mahall-regNo {
    padding-bottom: 0px !important;
    // margin-bottom: 5px;
    // font-size: 14px !important;
  }
  .img-demo {
    height: 192px !important;
    display: none;
  }
  .mdrow {
    margin: 0px;
  }
  .varisangya {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 376px) {
  .line-between {
    height: 2px;
    position: absolute;
    top: 18%;
    left: 75%;
    width: 40px;
  }
  .varisangyadiv {
    min-width: 143px !important;
  }
}

@media screen and (max-width: 320px) {
  .secondDiv {
    width: 30%;
  }

  .home_cont {
    margin-top: 0%;
    padding-bottom: 200px;
  }

  .section {
    padding-bottom: 0px;
    padding-top: 50px;
  }

  .card-poup {
    max-width: 300px;
    margin-bottom: 100px;
    margin-top: -5rem;
    height: fit-content;
  }

  .line-between {
    height: 2px;
    position: absolute;
    top: 18%;
    left: 75%;
    width: 35px;
  }

  .myp-5 {
    padding: 1rem;
  }
}

.numberField label {
  text-align: start !important;
  width: 100% !important;
}

.modal_section {
  top: 470px;
}

@media screen and (max-width: 425px) {
  .loginCard {
    text-align: center;
    margin: 0 auto;
  }

  .content-certificate {
    margin-left: 10px;
  }

  .btn-collection {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .banner-field {
    margin-top: 20px;
  }

  .p-card {
    margin: 20px;
  }

  .eduHistory {
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }

  .eduHistory .btn {
    margin: 0 !important;
    margin-bottom: 2rem !important;
  }

  .sm\:w-80 {
    width: 80% !important;
  }

  .sm\:d-none {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .customDisplay {
    display: block !important;
  }

  .otp_wrap_new {
    width: fit-content !important;
  }
}

@media screen and (max-width: 768px) {
  .md\:mb-4 {
    margin-bottom: 2rem !important;
  }

  .address-p {
    font-size: 12px;
  }

  .img-demo {
    height: 250px !important;
  }

  // .banner-field {
  //   margin-top: 20px;
  // }

  // .home_wrapper {
  //   margin-bottom: 120px;
  // }

  .button {
    margin-top: 15px;
  }

  .end-date {
    margin-top: 20px;
  }

  .wrapper_otp div {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
  }
}

.otp_wrap_new {
  width: 75%;
}

@media screen and (max-width: 580px) {
  .md\:flex-col {
    flex-direction: column-reverse;
  }

  .img2 {
    margin-top: 15px;
  }

  .sm\:w-100 {
    width: 100% !important;
  }

  .sm\:m-auto {
    width: fit-content !important;
    margin: auto !important;
  }

  .btn-date {
    margin-top: 20px;
  }
}

.wrapper_otp div {
  display: flex;
  justify-content: space-between;
}

.wrapper_otp input:last-child {
  margin-right: 0 !important;
}

@media screen and (max-width: 360px) {
  .sm\:p-0 {
    padding: 0 !important;
  }

  .currency-price {
    bottom: 0 !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }
}

.bg-khaf {
  background-color: #009846 !important;
}

.bg-khaf-blue {
  background-color: #093353 !important;
}

.text-khaf-blue {
  color: #093353 !important;
}

.text-khaf {
  color: #009846 !important;
}

.filterClose:focus {
  outline: none;
  box-shadow: none;
}

.main-prant-login {
  top: 50%;
  transform: translateY(-50%);
}

.display-md-none {
  display: block;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.custom-login-card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;

  @media screen and (max-width: 1500px) {
    margin-right: 2rem;

    // .home_wrapper {
    //   margin-bottom: 120px;
    // }
  }

  @media screen and (max-width: 1200px) {
    margin: auto;
  }
}

.custom-login-child {
  width: 460px;
  min-width: 400px;
  background: #ffffffdb;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // max-height: 500px;

  @media screen and (max-width: 480px) {
    border-radius: 0;
    box-shadow: none;
    background: #fff;
  }
}

.loginCard {
  max-height: 430px;
  padding: 2rem;
  padding-top: 0;
  padding-bottom: 0;
}

.login-form-wrap {
  align-items: flex-start;
  justify-content: space-between;
  height: 440px !important;
}

.login-bg-image {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  filter: grayscale(70%);
}

.button_submit_eq {
  flex-direction: column;
  align-items: center;
}

.eq-button-new {
  font-size: 16px !important;
}

//loading icon

.load-container {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
}

.outer-ring {
  color: #0b3354;
  position: relative;
  border-radius: 50%;
  border: 9px solid #0b3354;
  border-top: 9px solid #009846;
  z-index: 1000;
  width: 60px;
  height: 60px;
  box-shadow: 1px rgba(0, 0, 0, 0.2);
  animation: spiner 1s infinite linear;
}

@keyframes spiner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gallery {
  display: flex;
  // padding: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.gallery::-webkit-scrollbar {
  display: none;
}

.gallery-img {
  width: 100%;
  height: 250px;
  padding: 10px;
  border-radius: 15px;
}

.label-icon {
  font-family: sans-serif;
  font-style: normal;
}

@media screen and (max-width: 580px) {
  .btn-label {
    margin-top: 20px;
  }

  .btn-count {
    display: flex;
    justify-content: center;

    .text-ledger {
      padding-right: 30px;
    }
  }

  .logo-certificate {
    display: none;
  }
}

.text-ledger {
  padding-right: 70px;
}

.blink {
  animation: blink 1s ease infinite;
  transform: scale(1);
}

@keyframes blink {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.react__arrowPrev {
  position: absolute;
  top: 50%;
  left: -8px;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #093353 !important;
}

.react__arrowNext {
  position: absolute;
  right: -8px;
  top: 50%;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #093353 !important;
}

.react__arrowPrev:hover,
.react__arrowNext:hover {
  opacity: 1;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-button-next {
  right: 10px;
  left: auto;
  top: 25px;
}

.swiper-button-next:after {
  font-size: 25px;
  font-weight: bold;
}

.swiper-button-prev {
  right: 50px;
  left: auto;
  top: 25px;
}

.swiper-button-prev:after {
  font-size: 25px;
  font-weight: bold;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.toggle-switch {
  bottom: 30px;
}

.btn-approved {
  transition: all ease-in-out 0.3s;
  border-color: #093353;
}

.btn-approved:hover {
  background-color: #009846 !important;
  letter-spacing: 1.2px;
  color: #fff !important;
}

.profile-img {
  width: 120px;
  height: 120px;
  margin-top: 12px;
}

.sort {
  border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.date_close {
  position: absolute;
  top: 30px;
  right: 38px;
  font-size: 17px !important;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}

@media print {
  .print-button {
    display: none;
  }

  .btn-group {
    display: none;
  }

  .content-certificate {
    margin-left: 100px;
  }

  .certificate-form-content {
    margin-top: 30px;
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 5000px) {
  .certificate-layout {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media only screen and (min-width: 765px) and (max-width: 5000px) {
  .zakat-div {
    min-width: 143px;
  }
  .varisnkya {
    margin-top: 30px;
  }
  .varisangya-div {
    display: flex;
    justify-content: flex-end;
    // margin-top: 20px;
  }
  .type-button {
    display: flex;
  }
  .certificate-form-content {
    padding-left: 30px;
    margin-top: 40px;
  }

  .badge-institute-type {
    background-color: white;
    color: #000;
    margin-left: 30px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 13px;
  }

  .institute-name {
    padding-left: 30px;
  }

  .institute-detail-div {
    display: flex;
    justify-content: flex-end;
  }

  .btn-download-certificate {
    display: flex;
    justify-content: flex-end;
  }

  .text-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0.9;
  }

  .sub-middle-div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .middle-div {
    color: #000;
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .address-div {
    background-color: white;
    border-radius: 6px;
    margin-right: 5px;
    margin-bottom: 5px;
    // border-radius: 6px;
    // position: absolute;
    // top: 172px;
    // right: 10;
    // width: 100%;
    // min-height: 50px;
    // left: 0;
  }
  .address-p {
    // font-size: 12px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px;
  }
}

.certificate-form-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.certificate-form-h5 {
  width: 20%;
  color: #000;
}

.certificate-form-p {
  width: 60%;
  font-size: 15px;
  color: #000;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .institute-name-div {
    margin-top: 15px;
  }

  .badge-institute-type {
    background-color: white;
    color: #000;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 13px;
  }

  .institute-name {
    padding-left: 10px;
  }

  .form-pagination {
    display: none;
  }

  .certificate-form-content {
    margin-top: 30px;
  }

  .type-button {
    display: flex;
    flex-direction: column;
  }

  .certificate-form-row {
    display: flex;
    flex-direction: column;
  }

  .certificate-form-h5 {
    width: auto;
    color: #000;
    padding: 5px;
  }

  .certificate-form-p {
    width: auto;
    font-size: 15px;
    color: #000;
    padding: 5px;
  }

  .btn-group-certificate {
    display: flex;
    justify-content: center;
  }

  .btn-download-certificate {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .span-column {
    display: none;
  }

  .reciept-logo {
    display: none;
  }

  .text-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .sub-middle-div {
    padding-bottom: 25px;
  }

  .middle-div {
    color: #000;
    margin-top: 25px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .address-div {
    background-color: white;
    border-radius: 6px;
    margin: 5px;
  }
  .address-p {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px;
  }
  .bg-img-card {
    display: none;
  }
}

.header-div {
  align-items: center;
  border-bottom: 2px solid black;
  margin-top: 10px;
}

.font-p {
  font-size: 15px;
}

.font-h5 {
  width: 100px;
  color: #000;
}
.main-card {
  background-color: #cafbca;
  // position: relative;
}
.img-demo {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.main-div {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  margin-bottom: 1px;
}
.mahall-name {
  color: #009846;
  font-weight: 700;
  padding-bottom: 10px;
}
.mahall-date {
  font-weight: 550;
  font-size: 15px;
  padding-bottom: 12px;
}
.mahall-regNo {
  color: rgb(57, 57, 57);
  font-weight: 550;
  font-size: 18px;
  padding-bottom: 12px;
}
.mahall-type {
  right: unset !important;
  width: unset !important;
  color: #009846;
  font-weight: 700;
  left: 300px;
  position: absolute;
}
.mhluser-cardbody {
  padding: 15px 10px;
}

.bg-img-card {
  width: 100%;
  object-fit: cover;
  height: 240px;
}

.certificate-form-row-pdf {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.certificate-form-h5-pdf {
  width: 20%;
  color: #000;
}

.certificate-form-p-pdf {
  width: 60%;
  font-size: 15px;
  color: #000;
}

.middle-div-pdf {
  color: #000;
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}

.sub-middle-div-pdf {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.slider-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  width: 100%;
}
.slider-arrow .slider-btn {
  cursor: pointer;
  background: rgb(233, 232, 232);
  width: 45px;
  height: 45px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  color: black;
}
.slider-arrow .slider-btn.slider-prev {
  left: 20px;
}

.slider-arrow .slider-btn.slider-prev i {
  margin-right: 2px;
}

.slider-arrow .slider-btn.slider-next {
  right: 20px;
}

.slider-arrow .slider-btn.slider-next i {
  margin-left: 2px;
}

.slider-arrow .slider-btn:hover {
  background-color: black;
  color: #fff;
  border-color: white;
}

.slider-arrow.slider-arrow-2 .slider-btn {
  width: 40px;
  height: 40px;
  line-height: 44px;
  font-size: 24px;
}
.carausel-4-columns-cover .carausel-4-columns-arrow {
  margin-top: -100px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.notification_bar {
  background-color: #e0e6ff !important;
  padding: 10px;
  color: #3b3b3b;
}

.notification-banner-link {
  color: #3b5dff;
  text-decoration: underline !important;
}

.privacy-black{
  color: #000;
  font-weight: bold;
}

.privacy-color{
  color: #979797;
}
.bg-privacy {
  background-color: black !important;
}

.table-wrap-print th, .table-wrap-print td {
  padding: 3px;
}
.table-wrap-print tr, .table-wrap-print td, .table-wrap-print th{
  border-width: 1px !important;
  border-color: #8c8c8c !important;
  border-style: solid !important;
}